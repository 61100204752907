<template>
  <div class="elmain">

    <el-button type="success" @click="add">新增</el-button>
    <el-button type="primary" @click="ercode">领料码</el-button>
    <el-button type="danger" v-if="!not_stock" @click="getkc">库存不足</el-button>
    <el-button type="success" v-else @click="all">全部物料</el-button>
    <el-button style="float: right;" type="success" @click="exportToExcelDetails">导出明细</el-button>
    <el-button style="float: right;" type="success" @click="exportToExcelSummary">导出汇总</el-button>
    <!--库存不足提醒-->
    <el-button style="float: right;" type="danger" v-if="!not_stock" @click="openDialog">库存不足提醒</el-button>

    <div class="devList">
      <span class="devListbox" :class="type_id === '' ? 'active' : ''" @click="tab_type_id('')"> 全部类别</span>
      <span class="devListbox" :class="type_id === item ? 'active' : ''" @click="tab_type_id(item)" :key="index"
        v-for="(item, index) in types">{{ item }}</span>
    </div>
    <!-- 设置提醒员工 -->
    <el-dialog title="库存不足提醒" :visible.sync="dialogVisible" @close="handleClose">
      <el-form :model="form" label-width="80px">
        <el-select v-model="form.selectedStaff" multiple filterable placeholder="请选择员工" v-if="noticeStaffList !== null">
          <el-option v-for="staff in noticeStaffList" :key="staff.id" :label="staff.name" :value="staff.id" />
        </el-select>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确认</el-button>
      </span>
    </el-dialog>
    <!-- 二维码 -->
    <el-dialog title="领料码" :visible.sync="dialogFormVisible2">

    </el-dialog>
    <!-- 修改框 -->
    <el-dialog title="耗材信息" :visible.sync="dialogFormVisible">
      <el-form :model="dialogData">
        <el-form-item label="类别" :label-width="formLabelWidth">
          <el-select allow-create style="width:100%;" filterable v-model="dialogData.type" placeholder="请选择">
            <el-option v-for="item in types" :key="item.id" :label="item" :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="名称" :label-width="formLabelWidth">
          <el-input v-model="dialogData.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="规格" :label-width="formLabelWidth">
          <el-input v-model="dialogData.spec" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="单位" :label-width="formLabelWidth">
          <el-input v-model="dialogData.unit" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="安全库存" :label-width="formLabelWidth">
          <el-input-number v-model="dialogData.safe_stock" autocomplete="off"></el-input-number>
        </el-form-item>
        <el-form-item label="剩余库存" :label-width="formLabelWidth">
          <el-input-number v-model="dialogData.surplus_stock" autocomplete="off"></el-input-number>
        </el-form-item>
        <el-form-item label="单价" :label-width="formLabelWidth">
          <el-input v-model="dialogData.unit_price" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="备注" :label-width="formLabelWidth">
          <el-input v-model="dialogData.remark" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
    <!-- biao -->
    <div class="" style="width: 100%;">
      <el-table :data="list" border style="width: 100%;margin-top: 20px;">
        <el-table-column prop="type" label="类别" min-width="10%"></el-table-column>
        <el-table-column prop="name" label="名称" min-width="20%"></el-table-column>
        <el-table-column prop="spec" label="规格" min-width="10%"></el-table-column>
        <el-table-column prop="unit" label="单位" min-width="10%"></el-table-column>
        <el-table-column prop="safe_stock" label="安全库存" min-width="10%"></el-table-column>
        <el-table-column label="剩余库存" min-width="10%">
          <template slot-scope="scope">

            <span :style="scope.row.surplus_stock < scope.row.safe_stock ? 'color:red;' : ''">{{ scope.row.surplus_stock
            }}</span>

          </template>

        </el-table-column>
        <el-table-column prop="unit_price" label="单价" min-width="20%"></el-table-column>
        <el-table-column prop="remark" label="备注" min-width="20%"></el-table-column>
        <el-table-column label="领用记录" width="100" align="center">
          <template slot-scope="scope">
            <span class="ly" @click="linyong(scope.row)" type="warning" size="small">点击查看</span>

          </template>
        </el-table-column>
        <el-table-column label="操作" width="210" fixed="right">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="warning" size="small">编辑</el-button>
            <el-button type="danger" @click="deleteg(scope.row)" size="small">删除</el-button>
            <el-button @click="copy(scope.row)" type="primary" size="small">复制</el-button>

          </template>
        </el-table-column>
      </el-table>
      <div class="block" style="    margin-top: 10px;">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="page"
          :page-sizes="[5, 10, 50, 100]" :page-size="limit" layout="sizes, prev, pager, next" :total="total">
        </el-pagination>

      </div>
    </div>
    <el-dialog title="领用列表" :visible.sync="islylist">
      <el-table :data="lylist" border style="width: 100%;margin-top: 20px;">
        <el-table-column prop="consumable.name" label="名称" min-width="10%"></el-table-column>
        <el-table-column prop="staff_name" label="领用人" min-width="10%"></el-table-column>
        <el-table-column prop="created_at" label="领用时间" min-width="10%"></el-table-column>
        <el-table-column prop="created_at" label="类型" min-width="10%">
          <template slot-scope="scope">
            {{ scope.row.receive_nums >= 0 ? '领取' : '归还' }}
          </template>
        </el-table-column>
        <el-table-column prop="receive_nums" label="领用数量" min-width="10%">
          <template slot-scope="scope">
            {{ Math.abs(scope.row.receive_nums) }}
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-pagination @current-change="handleCurrentChange2" :current-page.sync="lyquer.page" :page-size="lyquer.limit" layout="sizes, prev, pager, next" :total="lytotal">
      </el-pagination> -->
      <el-pagination @current-change="handleCurrentChange2" :page-size="lyquer.limit" layout="prev, pager, next"
        :total="lytotal">
      </el-pagination>
    </el-dialog>
    <div class="make" v-show="iscode">
      <div class="title">领料码</div>
      <div>
        <div id="qrcode"></div>
        <div class="butbox">
          <el-button @click="iscode = false" type="info">关闭</el-button>
          <el-button @click="downLoad" type="primary">立即下载</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// import { parseTime } from '@/utlis/date.js';
import { addConsumable, getConsumable, deletConsumable, putConsumable, logs, getstaff ,noticeStaffConfig,getcompany} from "@/service/api";
// import { getcompany } from "@/service/lib/localToken";


import Cookies from 'js-cookie';
//import { saveAs } from 'file-saver';
//import ReminderDialog from "@/components/ReminderDialog.vue";

export default {
  data() {
    return {
      dialogVisible: false,
      notice_staff_ids: [], // 从后端获取的员工列表
      form: {
        selectedStaff: []// 选中的员工
      },
      staffList: [],
      noticeStaffList: null,
      consumable_id: '',
      type_id: '',
      formLabelWidth: '120px',
      devList: [],
      typelist: [],
      types: [],
      page: 1,
      limit: 10,
      list: [],
      total: 0,
      dialogFormVisible: false,
      dialogData: {
        name: '',
        type: '',
        safe_stock: 0,
        surplus_stock: 0,
        spec: '',
        unit: '',
        unit_price: '',
        remark: ''
      },
      dialogFormVisible2: false,
      type: 'add',
      iscode: false,
      islylist: false,
      lylist: [],
      lyquer: {
        page: 1,
        limit: 10
      },
      lyid: '',
      lytotal: 0,
      not_stock: '',
      baseURL: process.env.VUE_APP_APIHOST
    };
  },

  methods: {
    //导出
    exportToExcelDetails() {
      const companyId = Cookies.get('companyId');
  
      const url = `${this.baseURL}/consumable/receiveRecordReport?company_id=${companyId}&consumable_id=${this.consumable_id}&type=${this.type_id}`;
      window.open(url, '_blank');
    },
    exportToExcelSummary() {
      const companyId = Cookies.get('companyId');
      const url = `${this.baseURL}/consumable/receiveSummaryExport?company_id=${companyId}&consumable_id=${this.consumable_id}&type=${this.type_id}`;
      window.open(url, '_blank');
    },
    //库存不足提醒
    openDialog() {
      this.dialogVisible = true;
      const companyId = Cookies.get('companyId');
      getcompany(companyId).then(res => {
       
        let notice_staff_ids = res.data.notice_staff_ids.split(',');
  
     
        this.form.selectedStaff =    notice_staff_ids.map(item => Number(item));
      });
    },  

    handleConfirm() {
      // const token = getToken();
       const selectedStaffIds = this.form.selectedStaff.join(',');
       noticeStaffConfig({notice_staff_ids:selectedStaffIds}).then(res => {
        console.log(res);
        this.$message.success('提醒发送成功');
        this.dialogVisible = false;
      });

    },
    handleClose() {
      // 处理关闭逻辑
    },
    all() {
      this.not_stock = '';
      this.getlist();
    },
    getkc() {
      this.not_stock = 1;
      this.getlist();
    },
    copy(row) {
   
      this.dialogFormVisible = true;
      this.type = "add";
      this.dialogData = {

        name: row.name,
        type: row.type,
        safe_stock: row.safe_stock,
        surplus_stock: row.surplus_stock,
        spec: row.spec,
        unit: row.unit,
        unit_price: row.unit_price
      };
    },
    downLoad() {
      let myCanvas = document.getElementById('qrcode').getElementsByTagName('canvas');
      let a = document.createElement('a');
      a.href = myCanvas[0].toDataURL('image/png');
      a.download = '领料码';
      a.click();
      this.$message({
        message: "正在进行下载保存",
        type: 'success'
      });
    },
    linyong(row) {
     
      this.islylist = true;
      this.lyquer = {
        page: 1,
        limit: 10
      };
      this.lyid = row.id;
      this.getlogs();
    },
    getlogs() {
  let data = this.lyquer;
  data.id = this.lyid;
  logs(data).then(res => {
    this.lylist = res.data.list;
    this.lytotal = res.data.total;
  });
},
    tab_type_id(type) {
      this.type_id = type;
      this.getlist();
    },
    add() {
      this.dialogFormVisible = true;
      this.type = "add";
      this.dialogData = {
        name: '',
        type: '',
        safe_stock: 0,
        surplus_stock: 0,
        spec: '',
        unit: '',
        unit_price: '',
        remark: ''
      };

    },
    handleClick(row) {
    
      this.dialogFormVisible = true;
      this.type = "put";
      this.dialogData = {
        name: row.name,
        type: row.type,
        safe_stock: row.safe_stock,
        surplus_stock: row.surplus_stock,
        spec: row.spec,
        unit: row.unit,
        unit_price: row.unit_price,
        remark: row.remark,
        id: row.id
      };
    },
    deleteg(i) {

      let that = this;

      this.$confirm('此操作将永久删除该耗材, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          id: i.id
        };
        deletConsumable(data).then(res => {
          if (res.code === 200) {
            that.getlist();
            that.$message({
              type: 'success',
              message: '删除成功!'
            });

          }

        });

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    ercode() {

      this.getQrCode();
      this.iscode = true;
    },
    handleSizeChange(val) {
  
      this.limit = val;
      this.getlist();
    },
    handleCurrentChange(val) {

      this.page = val;
      this.getlist();
    },
    handleCurrentChange2(val) {
  this.lyquer.page = val;
  this.getlogs();
},

    submit() {
      if (this.dialogData.name === '') {
        this.$message.error('请输入名称');
        return;
      }
      if (this.dialogData.type === 0) {
        this.$message.error('请选择类别');
        return;
      }
      if (this.dialogData.safe_stock === 0) {
        this.$message.error('请输入安全库存');
        return;
      }
      // if (this.dialogData.surplus_stock === 0) {
      //   this.$message.error('请输入剩余库存');
      //   return;
      // }
      this.dialogData.safe_stock = Number(this.dialogData.safe_stock);
      this.dialogData.surplus_stock = Number(this.dialogData.surplus_stock);

      if (this.type === 'add') {
        addConsumable(this.dialogData).then(res => {
          if (res.code === 200) {
            this.$message({
              message: '添加成功',
              type: 'success'
            });
            this.getlist();
            this.dialogFormVisible = false;
          }

        });
      }
      if (this.type === 'put') {
   

        putConsumable(this.dialogData).then(res => {
          if (res.code === 200) {
            this.$message({
              message: '修改成功',
              type: 'success'
            });
            this.getlist();
            this.dialogFormVisible = false;
          }
        });
      }

    },

    getlist() {
  let data = { limit: this.limit, page: this.page, type: this.type_id, not_stock: this.not_stock };

  getConsumable(data).then(res => {
    this.list = res.data.list;
    this.total = res.data.total;
    this.types = res.data.types;
  });
},
    getQrCode() {


      this.$nextTick(() => {


        let id = Cookies.get('companyId');
        let qrcode = new this.QRCode('qrcode', {
          width: 200,
          height: 200,
          text: 'http://www.cncbkw.com/#/Claim?company_id=' + id, // 二维码地址
          colorDark: "#000",
          colorLight: "#fff"
        });
        console.log(qrcode);
      });


    },
    getstafflist() {
      let data = {
        limit: 999,
        page: 1,
        name: '',
        company_group_id:'',
        is_subscribe:1
      };
   
      getstaff(data).then(res => {
        this.noticeStaffList = res.data.list;
        this.noticeStaffList.forEach(function (value) {
          const manager_group_name = [];
          const manager_group_ids = [];
          value.manage_groups.forEach(function (item) {
            manager_group_name.push(item.name);
            manager_group_ids.push(item.id);
          });
          value.manager_group_ids = manager_group_ids;
          value.manager_group_name = manager_group_name.join(',');
        });
    
      });
    }

  },
  mounted() {
    this.getlist();
    this.getstafflist();

  }
};
</script>
<style scoped lang="scss">
.one {
  &-time {
    text-align: center;
  }
}

.top {
  padding: 20px 40px;
  // height: 80px;
  background: #ffffff;
  // display: flex;
  // align-items: center;

  &-item {
    display: flex;
    align-items: center;
    margin-right: 50px;

    .title {
      margin-right: 10px;
      font-size: 18px;
      font-weight: 700;
    }

    .data {
      margin: 0 30px;
      padding: 10px 0;

      border-radius: 30px;
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.hover {
        background: rgb(100, 181, 246);
      }
    }
  }
}

.elmain {
  padding: 50px;
}

@media screen and (max-width: 750px) {
  .top {
    padding: 10px;
  }

  .top-item {
    flex: 1;
  }

  .top-item .data {
    margin: 0 10px;
    width: auto;
    font-size: 12px;
    padding: 5px;
  }
}

.one-time {
  font-size: 16px;
  line-height: 24px;
}

.devList {
  width: 100%;
  margin-top: 20px;
}

.devListbox {
  display: inline-block;
  font-size: 14px;
  padding: 8px 10px;
  background: #209e91;
  color: white;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
  margin-top: 10px;
}

.active {
  background: #e6a23c;
}

.ly {
  cursor: pointer;
  text-decoration: underline;
}

.make {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99999999;
  padding-top: 20%;
  text-align: center;

  .title {
    color: white;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 30px;
  }

  #qrcode {
    width: 200px;
    text-align: center;
    margin: 0 auto;

    img {
      width: 100%;
    }
  }

  .butbox {
    margin-top: 20px;
  }
}
</style>
